<template>
	<div class="sidebar-view">
		<div class="sidebar-container">
			<div class="skeleton-view" v-if="dataList.length == 0"><el-skeleton :rows="16" animated /></div>
			<template v-else>
				<div class="row cb active-item" v-if="majorInfo.major_name">
					<div class="name">{{ majorInfo.major_name }}</div>
				</div>
				<template v-for="(item, index) in dataList">
					<div class="col group" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)">
						<div class="row cb group-name" :class="{ active: active === index }">
							<span class="text">{{ item.name }}</span>
							<i class="el-icon-arrow-right"></i>
						</div>
						<transition name="el-fade-in-linear">
							<div class="child-group el-fade-in" v-show="active === index">
								<template v-for="(value, key) in childrenList">
									<div class="row cb cate-group-title">
										<div class="name">{{ value.name }}</div>
									</div>
									<div class="row list-group">
										<template v-for="(v, k) in value.children">
											<div class="row cate-item" @click="setMajor(v, key)">
												<div class="name">{{ v.name }}</div>
											</div>
										</template>
									</div>
								</template>
							</div>
						</transition>
					</div>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	data() {
		return {
			active: '',
			timeout: '',
			dataList: []
		};
	},
	computed: {
		...mapState(['majorInfo']),
		childrenList() {
			if (this.dataList.length !== 0 && this.active !== '') {
				return this.dataList[this.active].children;
			} else {
				return [];
			}
		}
	},
	methods: {
		handleSelect(key, keyPath) {
			console.log(key, keyPath);
		},
		mouseenter(index) {
			clearTimeout(this.timeout);
			this.active = index;
		},
		mouseleave(index) {
			this.timeout = setTimeout(() => {
				this.active = '';
			}, 200);
		},
		setMajor(v, key) {
			this.$service.post('category/setSubject', { subject_id: v.id }).then(() => {
				this.$store.dispatch('setMajor', {
					major_id: v.id,
					major_name: v.name,
					major: `${this.dataList[this.active].name}/${this.childrenList[key].name}/${v.name}`
				});
				this.$message({
					type: 'success',
					message: '科目选择成功'
				});
				setTimeout(() => {
					window.location.reload();
				}, 800);
			});
		}
	},
	async created() {
		let res = await this.$service.post('category/majorIndex');
		this.dataList = res.data;
	}
};
</script>

<style scoped lang="less">
@import './sidebar.less';
</style>
